import "./App.css";
import { useEffect, useRef, useState } from "react";
import { createCsv } from "./utils";
import { v4 as uuid } from "uuid";

const shapeTestSequence = [
  {
    id: "rectangle",
    instructions:
      "Obkreslete obdelník. Začněte v levém horním rohu a obtahujte ve směru hodinových ručiček.",
    image: "rectangle",
  },
  {
    id: "cross",
    instructions:
      "Obkreslete kříž. První tah začněte v levém horním rohu a druhý v pravém horním rohu.",
    image: "cross",
  },
  {
    id: "signature",
    instructions: "Podepište se",
    image: "empty",
  },
];

function App() {
  const [pointerDataList, setPointerDataList] = useState([]);
  const [pointerType, setPointerType] = useState(null);
  const [testName, setTestName] = useState("");
  const [currentShapeTestIndex, setCurrentShapeTestIndex] = useState(0);
  const [isPointerDown, setIsPointerDown] = useState(false);
  const canvasRef = useRef(null);

  function handlePointerDown() {
    setIsPointerDown(true);
  }

  function handlePointerUp() {
    setIsPointerDown(false);
  }

  function handlePointerMove(event) {
    if (isPointerDown) {
      const canvasStartingPoint = canvasRef.current.getBoundingClientRect();

      setPointerDataList([
        {
          x: event.clientX - canvasStartingPoint.x,
          y: event.clientY - canvasStartingPoint.y,
          timestamp: event.timeStamp,
          pressure: event.pressure,
          tangentialPressure: event.tangentialPressure,
        },
        ...pointerDataList,
      ]);

      setPointerType(event.pointerType);
    }
  }

  function handleTestNameChange(newTestName) {
    setTestName(newTestName);
  }

  function sendTestResultToApi() {
    const jsonData = {
      testName,
      pointerType,
      userAgent: navigator.userAgent,
      testShape: shapeTestSequence[currentShapeTestIndex].id,
    };

    const id = uuid();
    const formData = new FormData();
    const csv = createCsv(pointerDataList, ["x", "y", "pressure", "timestamp"]);
    const csvBlob = new Blob([csv], { type: "text/csv" });
    const jsonBlob = new Blob([JSON.stringify(jsonData, null, 2)], {
      type: "application/json",
    });

    formData.append("csv", csvBlob, `${id}.csv`);
    formData.append("json", jsonBlob, `${id}.json`);

    return fetch("https://apigw.apps.r73.info/upload-bio-data", {
      method: "post",
      body: formData,
    });
  }

  async function handleSaveClick() {
    await sendTestResultToApi();

    if (currentShapeTestIndex === shapeTestSequence.length - 1) {
      alert("Test finished");
      setTestName("");
    }

    setCurrentShapeTestIndex(
      (currentShapeTestIndex + 1) % shapeTestSequence.length
    );
    setPointerDataList([]);
  }

  useEffect(() => {
    const onPointerUp = () => handlePointerUp();
    window.addEventListener("pointerup", onPointerUp);

    return () => {
      window.removeEventListener("pointerup", onPointerUp);
    };
  }, []);

  useEffect(() => {
    const onPointerDown = () => handlePointerDown();
    window.addEventListener("pointerdown", onPointerDown);

    return () => {
      window.removeEventListener("pointerdown", onPointerDown);
    };
  }, []);

  const canvasImageSrc = `/test-${shapeTestSequence[currentShapeTestIndex].image}.svg#svgView(preserveAspectRatio(none))"`;

  return (
    <div className="App">
      <div className="content">
        <ol className="instructions">
          <li>
            <b>Zadejte název měření:</b>

            <input
              className="test-name"
              placeholder="Test name"
              value={testName}
              onChange={(event) => handleTestNameChange(event.target.value)}
            />
          </li>

          {testName && (
            <li>
              <p>
                <b>
                  Nakreslete na plátno obrazce podle návodu níže. Po nakreslení
                  každého obrazce stiskněte tlačítko "Uložit".
                </b>
              </p>

              <p>{shapeTestSequence[currentShapeTestIndex].instructions}</p>
            </li>
          )}
        </ol>

        {testName && (
          <>
            <div className="signature-canvas-wrapper">
              <img
                className="test-image"
                width="100%"
                height="100%"
                src={canvasImageSrc}
                alt="pointer test"
              />
              <div
                ref={canvasRef}
                className="signature-canvas"
                onPointerMove={(event) => handlePointerMove(event)}
              ></div>
            </div>

            <div className="pointer-data-list">
              {pointerDataList.slice(0, 50).map((pointerData, index) => {
                const pointerDataStr = `${pointerData.timestamp}::P:${pointerData.pressure}:TP:${pointerData.tangentialPressure}:[${pointerData.x},${pointerData.y}]`;

                return <div key={index}>{pointerDataStr}</div>;
              })}
            </div>
          </>
        )}

        <div className="bottom-bar">
          <button
            disabled={!testName || !pointerDataList.length}
            onClick={handleSaveClick}
          >
            Uložit
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
